import * as Sentry from '@sentry/sveltekit';
import { handleErrorWithSentry } from '@sentry/sveltekit';

Sentry.init({
	dsn: 'https://4362939cf43f2a6c0b92e225cf18e642@o4504720210526208.ingest.us.sentry.io/4507963821064192',
	denyUrls: ['localhost:3001'],
	enabled: process.env.NODE_ENV !== 'development'
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
